import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './global'
import './interception'
import formatNumberWithCommas from '@/utils/money'
import addressBook, { isUseLatestLogic } from '@/utils/submitContacts'

// import FastClick from 'fastclick'
// FastClick.attach(document.body)
// import VConsole from 'vconsole'
// new VConsole({})
Vue.config.productionTip = false
Vue.prototype.formatNumberWithCommas = formatNumberWithCommas
Vue.prototype.addressBook = addressBook
Vue.prototype.isUseLatestLogic = isUseLatestLogic

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
